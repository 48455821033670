.discovery-card {
  top: 0;
  box-shadow: var(--shadow-over-grey-background);
  border-radius: var(--card-radius);
  margin-bottom: 15px;
}
.discovery-card__body {
  background-color: #ffffff;
  margin-top: 0px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 15px;
  padding: 15px;
}
.discovery-card__qr-preview {
  border-radius: 15px;
}
.discovery-card__qr-code-pic {
  vertical-align: top;
  width: 120px;
  height: 120px;
  border-radius: 15px;
}

.discovery-card__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
}

.discovery-brandName {
  font-size: 24px;
  font-weight: 900;
  color: var(--black);
  text-align: left;
  margin-top: 10%;
}

.catagory {
  font-size: 14px;
  font-weight: 500;
  color: var(--black);
  text-align: left;
  margin-bottom: 5%;
  color: #b7bdc2;
}

.discovery-back {
  margin-left: 15px;
  bottom: 0;
  cursor: pointer;
}

.discovery-program__button {
  width: 100%;
  box-shadow: var(--shadow-over-grey-background);
  margin-bottom: 15px;
  background-color: #f87c56 !important;

  font-size: 16px;
  height: fit-content;
  font-weight: 500;
  padding: 5px 0px;
}

.discovery-program__button .active {
  background-color: #f87c56;
}

.discover__heading {
  font-size: 24px;
  font-weight: 800;
}

.discovery__description {
  padding: 20px 15px;
}

.discover__section {
  margin-bottom: 30px;
}

.discovery__location {
  padding: 15px 15px 0px 15px;
  font-size: 20px;
  font-weight: 800;
}
