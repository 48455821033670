.__expiration {
  display: flex;
  align-items: center;
  row-gap: 16px;
  /* padding-bottom: 7px;
    border-bottom: 1px solid #000000; */
}

.__calendar {
  margin-right: 18px;
  flex-shrink: 0;
}

.__expiration-details {
  flex: 1;
}

.__days-left {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 12px;
}

.__expiration-date {
  font-size: 10px;
  line-height: 14px;
}

.__full-address {
}

.__details {
  margin-top: 2px;
}

.__conversion_rate {
  font-size: 12px;
  line-height: 16px;
}

.__card {
  position: relative;
  display: block;
  width: 100%;
  border: none;
  margin-top: 12px;
  padding: 12px 22px;
  background-color: #ffffff;
  box-shadow: var(--shadow-over-white-background);
  border-radius: var(--card-radius);
  font-family: inherit;
  font-weight: inherit;
  text-decoration: none;
  color: inherit;
}

.__conversion_rates {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.__conversion_rate {
  font-size: 12px;
  color: #666;
  display: grid;
  grid-template-columns: minmax(120px, 1fr) 2fr;
  align-items: center;
  gap: 8px;
}

.__location_name {
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.__conversion_details {
  color: #666;
}

/* For single location display */
.__single_conversion {
  font-size: 12px;
  color: #666;
  padding: 4px 0;
}

.__conversion_header {
  display: grid;
  grid-template-columns: minmax(120px, 1fr) 2fr;
  gap: 8px;
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
}

.__header_location,
.__header_conversion {
  font-size: 12px;
  font-weight: 600;
  color: #333;
  text-transform: uppercase;
}

.milestone-rewards-table {
  margin: 20px 0;
  padding: 15px;
  background: white;
  border-radius: 8px;
}

.milestone-rewards-table h3 {
  margin-bottom: 15px;
  color: #333;
}

.milestone-rewards-table table {
  width: 100%;
  border-collapse: collapse;
}

.milestone-rewards-table th,
.milestone-rewards-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.milestone-rewards-table th {
  font-weight: 600;
  color: #666;
}

.deal__milestone-rewards {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.deal__milestone-rewards h3 {
  margin-bottom: 15px;
  font-size: 14px;
  color: #333;
}

.deal__milestone-rewards table {
  width: 100%;
  border-collapse: collapse;
}

.deal__milestone-rewards th,
.deal__milestone-rewards td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #eee;
  font-size: 12px;
}

.deal__milestone-rewards th {
  font-weight: 600;
  color: #666;
}

.deal__milestone-rewards td:first-child {
  width: 120px;
  white-space: nowrap;
}

.deal__milestone-rewards td:last-child {
  padding-left: 15px;
}
