.container {
    max-width: 704px;
    border-left: var(--container-padding) solid transparent;
    border-right: var(--container-padding) solid transparent;
    margin-left: auto;
    margin-right: auto;
}

.container--smartphone-column {
    max-width: 400px;
}
