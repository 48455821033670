/* @font-face {
  font-display: swap;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 200;
  src: url("../../assets/fonts/nunito-sans-v15-latin-200.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 300;
  src: url("../../assets/fonts/nunito-sans-v15-latin-300.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../../assets/fonts/nunito-sans-v15-latin-regular.woff2")
    format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 500;
  src: url("../../assets/fonts/nunito-sans-v15-latin-500.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  src: url("../../assets/fonts/nunito-sans-v15-latin-600.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  src: url("../../assets/fonts/nunito-sans-v15-latin-700.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 800;
  src: url("../../assets/fonts/nunito-sans-v15-latin-800.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 900;
  src: url("../../assets/fonts/nunito-sans-v15-latin-900.woff2") format("woff2");
} */

@font-face {
  font-family: "Questrial", sans-serif;
  font-weight: 400;
  font-style: normal;
  src: url("../../assets/fonts/Questrial-Regular.ttf") format("woff2");
}
