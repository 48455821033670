.brief {
    padding: 8px 0 16px;
    background-color: #FFFFFF;
    box-shadow: var(--shadow-over-grey-background);
    border-radius: var(--card-radius);
}
    .brief__heading {
        margin: 0 16px 8px;
        font-weight: var(--black);
        font-size: 18px;
        line-height: 24px;
    }

    .brief__actions {
        display: flex;
        flex-direction: column;
        gap: 2px;
    }
