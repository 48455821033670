.null {
    padding-bottom: 40px;
    text-align: center;
}
    .null__icon {
        display: block;
        margin-left: auto;
        margin-right: auto;
        vertical-align: top;
        fill: #B7BDC2;
    }

    .null__message {
        margin-top: 8px;
        font-size: 18px;
        line-height: 30px;
        color: #B7BDC2;
    }
