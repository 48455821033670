.meed-offers-wrapper {
  min-height: 100vh;
  background: #f5f5f5;
  width: 100%;
  overflow-x: hidden;
  position: relative;
  margin-top: -5px;
}

.meed-offers-container {
  width: 100%;
  min-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  background: #f5f5f5;
  padding: 0 !important;
  max-width: 100vw !important;
}

.meed-offers {
  padding: 20px 16px;
  max-width: 1500px;
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  box-sizing: border-box;
}

.meed-offers__title {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 8px;
}

.meed-offers__subtitle {
  font-size: 16px;
  text-align: center;
  color: #666;
  margin-bottom: 24px;
}

.meed-offers__location-buttons {
  display: flex;
  gap: 12px;
  justify-content: center;
  margin-bottom: 24px;
}

.location-button {
  min-width: 120px;
  border-radius: 20px;
}

.location-button.ant-btn-primary {
  background-color: #e65100;
}

.location-button.ant-btn-primary:hover,
.location-button.ant-btn-primary:focus {
  background-color: var(--brand) !important;
}

.location-button.ant-btn-primary:focus-visible {
  outline: none;
  box-shadow: 0 0 0 2px rgba(230, 81, 0, 0.2);
}

.location-button.ant-btn-default {
  border-color: #e65100;
  color: #e65100;
}

.location-button.ant-btn-default:hover,
.location-button.ant-btn-default:focus {
  color: #f57c00 !important;
  border-color: #f57c00 !important;
}

.location-button.ant-btn-default:focus-visible {
  outline: none;
  box-shadow: 0 0 0 2px rgba(230, 81, 0, 0.2);
}

.meed-offers__list {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr;
  width: 100%;
  box-sizing: border-box;
  min-height: 200px; /* Prevent layout shift during animation */
}

/* Responsive grid layout */
@media (min-width: 768px) {
  .meed-offers__list {
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }
}

@media (min-width: 1024px) {
  .meed-offers__list {
    grid-template-columns: repeat(3, minmax(300px, 1fr));
    gap: 32px;
    padding: 0 16px;
  }
}

@media (min-width: 1400px) {
  .meed-offers__list {
    grid-template-columns: repeat(4, minmax(300px, 1fr));
    gap: 32px;
    padding: 0 16px;
  }
}

/* Update card styles */
.meed-offers__list .deal__card {
  background: #ffffff;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
}

.meed-offers__list .deal__body {
  display: flex;
  align-items: center;
}

.meed-offers__list .deal__preview {
  flex-shrink: 0;
}

/* Add these styles for the modal */
.voucher-detail-modal {
  .ant-modal-content {
    border-radius: 12px;
    overflow: hidden;
  }
}

.voucher-detail {
  padding: 24px;
}

.voucher-detail__content {
  margin-bottom: 24px;
}

.voucher-detail__discount {
  font-size: 24px;
  font-weight: 600;
  color: var(--brand);
  margin: 16px 0;
}

.qr-code-container {
  text-align: center;
  padding: 24px;
  background: #f5f5f5;
  border-radius: 8px;

  p {
    margin-top: 16px;
    color: #666;
  }
}

.join-button {
  background-color: var(--brand);
  border: none;
  height: 48px;
  font-size: 16px;
}

/* Ensure smooth transitions */
.meed-offers__location-buttons .location-button {
  transition: all 0.3s ease;
}

/* Add these styles for header and footer */
.meed-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background-color: rgb(255, 238, 215);
  min-height: 80px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
  position: relative;
  z-index: 10;
  width: 100%;
  top: 0;
  left: 0;
}

.meed-logo {
  width: 120px;
  min-width: 120px; /* Prevent logo from shrinking */
  height: auto;
}

.meed-nav {
  display: flex;
  gap: 24px;
  align-items: center;
}

.meed-nav a {
  color: #333;
  text-decoration: none;
  font-weight: 700;
  position: relative;
}

/* Add divider style */
.meed-nav a:not(:last-child)::after {
  content: "";
  position: absolute;
  right: -12px;
  top: 50%;
  transform: translateY(-50%);
  height: 16px;
  width: 2px;
  background-color: #333;
}

.meed-footer {
  background-color: #333333;
  padding: 40px;
}

.meed-footer-content {
  display: flex;
  align-items: flex-start;
  gap: 48px;
  max-width: 1200px;
  margin: 0 auto;
}

.meed-footer-logo {
  width: 120px;
}

.meed-footer-nav {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.meed-footer-nav a {
  color: #ffffff;
  text-decoration: none;
  font-size: 12px;
  opacity: 0.8;
  transition: opacity 0.2s;
  font-weight: 700;
}

.meed-footer-nav a:hover {
  opacity: 1;
}

/* Add media queries for responsive design */
@media (max-width: 480px) {
  .meed-header {
    padding: 16px 20px;
    flex-direction: column; /* Stack logo and nav vertically */
    align-items: flex-start; /* Align items to the left */
    gap: 16px; /* Space between logo and nav */
  }

  .meed-logo {
    width: 100px;
    min-width: 100px;
  }

  .meed-nav {
    padding-left: 4px; /* Match footer indent */
    flex-wrap: wrap; /* Allow nav items to wrap */
    gap: 16px;
  }

  .meed-nav a {
    font-size: 14px;
  }

  /* Remove dividers in mobile view */
  .meed-nav a:not(:last-child)::after {
    display: none;
  }

  /* Footer responsive styles */
  .meed-footer-content {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }

  .meed-footer-nav {
    padding-left: 4px; /* Slight indent under logo */
  }
}
