:root {
    --body-font-size: 16px;
    --body-line-height: 22px;
    --extra-light: 200;
    --light: 300;
    --regular: 400;
    --medium: 500;
    --semi-bold: 600;
    --bold: 700;
    --extra-bold: 800;
    --black: 900;
    --container-padding: 16px;
    --header-height: 75px;
    --nav-height: 75px;
}



/* Shadows
 * Note that in figma the same shadow is used all over the project. But it
 * doesn't look good over white background. Top part can't be seen over it.
 */

:root {
    --card-radius: 15px;
    --shadow-over-grey-background: 0 4px 4px rgba(0, 0, 0, 0.25);
    --shadow-over-white-background: 0 2px 4px rgba(0, 0, 0, 0.25);
}

@media (max-height: 789px) {
    :root {
        --shadow-over-grey-background: 0 3px 3px rgba(0, 0, 0, 0.25);
        --shadow-over-white-background: 0 1px 3px rgba(0, 0, 0, 0.25);
    }
}
