.place {
  display: flex;
  flex-direction: column;
  height: 230px;
  border-radius: var(--card-radius);
  box-shadow: var(--shadow-over-white-background);
  color: #ffffff;
  box-shadow: 0px 1px 10px -2px grey;
}

.place__header {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  padding: 12px;
}

.place__card {
  text-decoration: none;
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
  position: relative;
  display: block;
  width: 100%;
  border: none;
  -webkit-tap-highlight-color: transparent;
}

.place__leaf {
  flex-shrink: 0;
  display: inline-block;
  vertical-align: top;
  border-radius: 8px;
  overflow: hidden;
}

.place__logo {
  vertical-align: top;
  width: auto;
  height: 40px;
}

.place__summary {
  margin-top: 2px;
  flex-grow: 1;
}

.place__heading {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: var(--extra-bold);
  font-size: 18px;
  line-height: 20px;
}

.place__location {
}

.place__location .location {
  color: inherit;
}

.place__data {
  flex-shrink: 0;
  text-align: right;
  margin-right: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.place__voucher {
  vertical-align: top;
  fill: currentColor;
  width: 22px;
  height: auto;
}

.place__counter {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}

.place__footer {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
}

.place__category {
  font-weight: var(--light);
  font-size: 14px;
  line-height: 20px;
}

.place__more {
  margin-bottom: -2px;
  color: inherit;
}

.place__chevron {
  vertical-align: top;
  width: 20px;
  height: 20px;
  fill: currentColor;
}
