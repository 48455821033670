.membership-card {
  box-shadow: var(--shadow-over-grey-background);
  border-radius: var(--card-radius);
}
.membership-card__body {
  padding: 9px 12px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: var(--brand);
  border-top-left-radius: var(--card-radius);
  border-top-right-radius: var(--card-radius);
}
.membership-card__qr-preview {
  padding: 5px;
  background-color: #ffffff;
  border-radius: 15px;
  width: fit-content;
  margin-left: auto;
}
.membership-card__qr-code-pic {
  vertical-align: top;
  padding: 5%;
  width: 160px;
  height: 160px;
}

.membership-card__footer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #ffffff;
  border-bottom-left-radius: var(--card-radius);
  border-bottom-right-radius: var(--card-radius);
}
.membership-card__name {
  margin-top: auto;
  padding: 10px 24px 9px 12px;
  font-size: 18px;
  line-height: 23px;
  text-decoration: none;
  color: inherit;
}

.membership-card__total-vouchers {
  padding: 13px 12px 9px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 18px;
  line-height: 21px;
  text-decoration: none;
  color: inherit;
}
.membership-card__voucher-icon {
  margin-bottom: 5px;
}

.None {
  background-color: var(--color);
  opacity: 1;
}

.default {
  background-color: var(--color);
  opacity: 1;
}

.Wavy {
  background-color: var(--color);
  opacity: 1;
  background-image: repeating-radial-gradient(
      circle at 0 0,
      transparent 0,
      var(--color) 19px
    ),
    repeating-linear-gradient(#f1f1f155, #f1f1f1);
}

.Circles {
  background-color: var(--color);
  opacity: 1;
  background-image: radial-gradient(
      circle at center center,
      #f1f1f1,
      var(--color)
    ),
    repeating-radial-gradient(
      circle at center center,
      #f1f1f1,
      #f1f1f1,
      19px,
      transparent 38px,
      transparent 19px
    );
  background-blend-mode: multiply;
}

.Diagonal {
  background-color: var(--color);
  opacity: 1;
  background: repeating-linear-gradient(
    45deg,
    #f1f1f1,
    #f1f1f1 9.5px,
    var(--color) 9.5px,
    var(--color) 47.5px
  );
}

.ZigZag {
  background-color: var(--color);
  opacity: 1;
  background-image: linear-gradient(135deg, #f1f1f1 25%, transparent 25%),
    linear-gradient(225deg, #f1f1f1 25%, transparent 25%),
    linear-gradient(45deg, #f1f1f1 25%, transparent 25%),
    linear-gradient(315deg, #f1f1f1 25%, var(--color) 25%);
  background-position:
    19px 0,
    19px 0,
    0 0,
    0 0;
  background-size: 38px 38px;
  background-repeat: repeat;
}

.ZigZag3D {
  background-color: var(--color);
  opacity: 1;
  background:
    linear-gradient(135deg, #f1f1f155 25%, transparent 25%) -19px 0/ 38px 38px,
    linear-gradient(225deg, #f1f1f1 25%, transparent 25%) -19px 0/ 38px 38px,
    linear-gradient(315deg, #f1f1f155 25%, transparent 25%) 0px 0/ 38px 38px,
    linear-gradient(45deg, #f1f1f1 25%, var(--color) 25%) 0px 0/ 38px 38px;
}

.Rhombus {
  background-color: var(--color);
  opacity: 1;
  background-image: linear-gradient(135deg, #f1f1f1 25%, transparent 25%),
    linear-gradient(225deg, #f1f1f1 25%, transparent 25%),
    linear-gradient(45deg, #f1f1f1 25%, transparent 25%),
    linear-gradient(315deg, #f1f1f1 25%, var(--color) 25%);
  background-position:
    19px 0,
    19px 0,
    0 0,
    0 0;
  background-size: 19px 19px;
  background-repeat: repeat;
}

.Paper {
  background-color: var(--color);
  opacity: 1;
  background-image: linear-gradient(
      #f1f1f1 3.8000000000000003px,
      transparent 3.8000000000000003px
    ),
    linear-gradient(
      90deg,
      #f1f1f1 3.8000000000000003px,
      transparent 3.8000000000000003px
    ),
    linear-gradient(
      #f1f1f1 1.9000000000000001px,
      transparent 1.9000000000000001px
    ),
    linear-gradient(
      90deg,
      #f1f1f1 1.9000000000000001px,
      var(--color) 1.9000000000000001px
    );
  background-size:
    95px 95px,
    95px 95px,
    19px 19px,
    19px 19px;
  background-position:
    -3.8000000000000003px -3.8000000000000003px,
    -3.8000000000000003px -3.8000000000000003px,
    -1.9000000000000001px -1.9000000000000001px,
    -1.9000000000000001px -1.9000000000000001px;
}

.Rectangles {
  background-color: var(--color);
  opacity: 1;
  background-image: repeating-linear-gradient(
      45deg,
      #f1f1f1 25%,
      transparent 25%,
      transparent 75%,
      #f1f1f1 75%,
      #f1f1f1
    ),
    repeating-linear-gradient(
      45deg,
      #f1f1f1 25%,
      var(--color) 25%,
      var(--color) 75%,
      #f1f1f1 75%,
      #f1f1f1
    );
  background-position:
    0 0,
    19px 19px;
  background-size: 38px 38px;
}

.Pluses {
  background-color: var(--color);
  opacity: 1;
  background:
    radial-gradient(
      circle,
      transparent 20%,
      var(--color) 20%,
      var(--color) 80%,
      transparent 80%,
      transparent
    ),
    radial-gradient(
        circle,
        transparent 20%,
        var(--color) 20%,
        var(--color) 80%,
        transparent 80%,
        transparent
      )
      47.5px 47.5px,
    linear-gradient(
        #f1f1f1 3.8000000000000003px,
        transparent 3.8000000000000003px
      )
      0 -1.9000000000000001px,
    linear-gradient(
        90deg,
        #f1f1f1 3.8000000000000003px,
        var(--color) 3.8000000000000003px
      ) -1.9000000000000001px 0;
  background-size:
    95px 95px,
    95px 95px,
    47.5px 47.5px,
    47.5px 47.5px;
}
