:root {
  --brand: #e06932;
  --primary-colour: #e06932;
  --primary-colour-hover: #ff8b5b;
  --gradient-colour-1: #da4509;
  --gradient-colour-2: #f8bf78;
  --font: "Questrial", sans-serif;
  --primary-font-color: #333333;
  --secondary-color: #04b290;
  --creamy-beige: #ffeed7;
  --light-grey-background: #f0f0f0;
  --light: 300;
  --extra-bold: 800;
  --black: 900; /* font weight, not color */
  --tooltip-background: rgb(0 0 0 / 90%);

  --ease-out-quint: cubic-bezier(0.22, 1, 0.36, 1);
  --transition-hover: 100ms ease;
  --transition-dropdown: 200ms ease;
  --transition-show: 300ms var(--ease-out-quint);

  --card-radius: 15px;
  --input-radius: 10px;

  --image-fallback: #f7f6fb;
  --input-border: #5d6164;
  --input-border-hover: var(--primary-colour);
  --placeholder-color: #5d6164;
  --disabled-background: #f6f6f6;
  --disabled-color: #5d6164;
  --success: #04b290;
  --danger: #ff0033;

  --grey: #b7bdc2;
  --dark-grey: #5d6164;
  --hover: rgb(183 189 194 / 30%); /* good for small elements such as buttons */
  --hover-lighter: rgb(
    183 189 194 / 15%
  ); /* good for big elements such as dropdown menu items */
  --active: rgb(183 189 194 / 45%);
  --white-hover: #fafafa;
}
