.progress {
    height: 12px;
    background-color: #DCDEE0;
    border-radius: 6px;
}
    .progress__value {
        height: 100%;
        background-color: var(--brand);
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }
