.deal {
  display: block;
  width: 100%;
  padding: 0;
  border: none;
  font-family: inherit;
  font-weight: inherit;
  text-decoration: none;
  color: inherit;
}
.deal__card {
  position: relative;
  display: block;
  width: 100%;
  border: none;
  margin-top: 12px;
  padding: 10px 12px 22px 40px;
  background-color: #ffffff;
  box-shadow: var(--shadow-over-white-background);
  border-radius: var(--card-radius);
  font-family: inherit;
  font-weight: inherit;
  text-decoration: none;
  color: inherit;
}

.deal__vocher {
  position: relative;
  display: block;
  width: 100%;
  border: none;
  margin-top: 0px;
  margin-bottom: 10px;
  padding: 0px 0px 0px 15px;
  background-color: #ffffff;
  border-radius: var(--card-radius);
  font-family: inherit;
  font-weight: inherit;
  text-decoration: none;
  color: inherit;
}

.deal__card:first-child {
  margin-top: 0;
}

.deal__card--details {
  margin-top: 30px;
}
.deal__stripe {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 28px;
  border-top-left-radius: var(--card-radius);
  border-bottom-left-radius: var(--card-radius);
}

.deal__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding-bottom: 4px;
  padding-top: 2px;
  border-bottom: 1px solid #000000;
}
.deal__venue {
  font-size: 18px;
  line-height: 20px;
}

.deal__countdown {
  flex-shrink: 0;
}

.deal__body {
  margin-top: 12px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.deal__preview {
  flex-shrink: 0;
  position: relative;
  width: 103px;
  height: 96px;
}
.deal__illustration {
  vertical-align: top;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #333333;
  border-radius: var(--card-radius);
}

.deal__leaf {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #dab992;
  border-bottom-left-radius: calc(
    var(--card-radius) - 1px
  ); /* -1 to get rid of visual bugs (white leaf over black photo over white background -- can see black artifacts) */
  border-top-right-radius: var(--card-radius);
  overflow: hidden;
}
.deal__logo {
  vertical-align: top;
  width: auto; /* by default we assume all the logos are squared or landscape oriented */
  height: 40px;
}

.deal__logo--portrait {
  width: 40px; /* in case of portrait we need to detect it using JS and add this class */
  height: auto;
}

.deal__content {
}
.deal__offer-heading {
  font-weight: var(--black);
  font-size: 20px;
  line-height: 22px;
}

.deal__offer-value {
  font-weight: var(--black);
  font-size: 24px;
  line-height: 22px;
}

.deal__location {
  margin-top: 14px;
}

.deal__expiration {
  display: flex;
  align-items: center;
  /* padding-bottom: 7px;
  border-bottom: 1px solid #000000; */
}
.deal__calendar {
  margin-right: 18px;
  flex-shrink: 0;
}

.deal__expiration-details {
}
.deal__days-left {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
}

.deal__expiration-date {
  font-size: 10px;
  line-height: 14px;
}

.deal__full-address {
  padding-top: 12px;
  margin-left: 2px;
  margin-bottom: -6px;
}

.deal__details {
  margin-top: 2px;
}
.deal__article {
}

.deal__article:first-child {
  margin-top: 0;
}

.deal__article:last-child {
  margin-bottom: -12px;
}

.deal__progress-inner {
  margin-left: -20px;
}
.deal__textual-progress {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}
.deal__unlocking-heading {
  flex-grow: 1;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
}

.deal__stats {
  flex-shrink: 0;
  margin-top: 2px;
}
.deal__unlocked-total {
  font-size: 18px;
  line-height: 24px;
  text-align: right;
  white-space: nowrap;
}

.deal__numbers {
  margin-top: 2px;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  white-space: nowrap;
}

.deal__visual-progress {
  margin-top: 15px;
  margin-bottom: -10px;
}

@media (width < 1500px) {
  .deal {
  }
  .deal__card {
    padding: 7px 15px 15px 35px;
  }

  .deal__card--details {
  }

  .deal__stripe {
    width: 20px;
  }

  .deal__head {
    gap: 24px;
  }
  .deal__venue {
    font-size: 18px;
    font-weight: light;
    line-height: 20px;
  }

  .deal__countdown {
  }

  .deal__body {
    margin-top: 8px;
    /*display: flex;*/
    /*align-items: center;*/
    gap: 12px;
  }
  .deal__preview {
    /*position: relative;*/
    width: 90px;
    height: 90px;
  }
  .deal__illustration {
  }

  .deal__leaf {
  }
  .deal__logo {
    height: 30px;
  }

  .deal__logo--portrait {
    width: 27px; /* in case of portrait we need to detect it using JS and add this class */
  }

  .deal__content {
  }
  .deal__offer-heading {
    font-size: 20px;
    line-height: 22px;
    /* padding-bottom: 4px; */
  }

  .deal__offer-value {
    font-size: 24px;
    line-height: 22px;
    /* padding-bottom: 4px; */
  }

  .deal__location {
    margin-top: 6px;
  }

  .deal__progress-inner {
    margin-left: -12px;
    padding: 3px 9px 6px 0;
  }
  .deal__textual-progress {
  }
  .deal__unlocking-heading {
  }

  .deal__stats {
  }
  .deal__unlocked-total {
  }

  .deal__numbers {
  }

  .deal__visual-progress {
  }
}
