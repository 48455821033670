.meed {

}
    .meed__card {

    }

    .meed__sub-heading {
        margin-top: 20px;
        margin-bottom: 0;
        font-weight: var(--black);
        font-size: 24px;
        line-height: 32px;
    }

    .meed__list {
        margin-top: 16px;
    }
        .meed__item {
            position: relative;
            margin-top: -170px;
        }

        .meed__item:first-child {
            margin-top: 0;
        }

    .meed__emptiness {
        margin-top: 20px;
    }
