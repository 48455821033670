/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
} */
.circlemenu {
  position: relative;
  width: 50px;
  height: 50px;
  /* background: #0f0; */
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  z-index: 1;
}

.circlemenu li {
  position: absolute;
  right: 85px;
  list-style: none;
  transform-origin: 100px;
  transition: 0.5s;
  transform: rotate(0deg) translateX(80px);
  z-index: 2;
}

.circlemenu li a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: var(--secondary-color);
  border-radius: 50%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
  transition: 0.5s;
  -webkit-tap-highlight-color: transparent;
}

.circlemenu li a:hover {
  color: #f00;
}

.circlemenu li[data-index="1"] a {
  transform: rotate(calc(90deg / -2 * 1));
}
.circlemenu li[data-index="2"] a {
  transform: rotate(calc(90deg / -2 * 2));
}
.circlemenu li[data-index="3"] a {
  transform: rotate(calc(90deg / -2 * 3));
}

.circlemenu.active li[data-index="0"] {
  transform: rotate(calc(90deg / 2 * 0));
}
.circlemenu.active li[data-index="1"] {
  transform: rotate(calc(90deg / 2 * 1));
}
.circlemenu.active li[data-index="2"] {
  transform: rotate(calc(90deg / 2 * 2));
}

.toggle {
  position: absolute;
  width: 60px;
  height: 60px;
  background: var(--brand);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
  font-size: 1em;
  transition: transform 1s;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.circlemenu.active .toggle {
  transform: rotate(360deg);
}

.toggle:focus,
.circlemenu li a:focus {
  box-shadow: none !important;
}
