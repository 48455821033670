.deal__voucher {
  padding: 12px;
  position: relative;
}

.deal__progress {
  position: relative;
  width: 100%;
  height: 8px;
  background-color: #f0f0f0;
  border-radius: 4px;
  overflow: visible;
}

.progress__value {
  position: absolute;
  height: 100%;
  border-radius: 4px;
  left: 0;
  top: 0;
  transition: width 0.3s ease;
}

.progress {
  position: relative;
}

.milestone-reward {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #f5f5f5;
  z-index: 2;
  box-shadow: 0 0 0 2px white;
  pointer-events: none;
}

.milestone-reward::after {
  display: none;
}

.milestone-reward.claimed {
  background-color: #52c41a;
  width: 24px;
  height: 24px;
}

.milestone-reward svg {
  width: 16px;
  height: 16px;
  color: #666666;
  flex-shrink: 0;
}

.milestone-reward.claimed svg {
  color: white;
}
