.camera-app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f0f2f5;
}

.camera-card {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.video-stream {
  width: 100%;
  max-width: 320px;
  border-radius: 10px;
  margin-bottom: 15px;
}

.button-group {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.top-button-group {
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  margin-bottom: 15px;
}

.animated-button {
  background-color: var(--primary-colour); /* Change button color to orange */
  border-color: var(--primary-colour); /* Change border color to orange */
  transition: transform 0.3s ease;
}

.animated-button:hover {
  background-color: darkorange !important; /* Change hover background color to dark orange */
  border-color: darkorange !important;
  transform: scale(1.05);
}

.back-to-meed-button {
  background-color: var(--primary-colour); /* Change button color to orange */
  border-color: var(--primary-colour); /* Change border color to orange */
  transition: transform 0.3s ease;
}

.back-to-meed-button:hover {
  background-color: darkorange !important; /* Change hover background color to dark orange */
  border-color: darkorange !important;
  transform: scale(1.05);
}

.bottom-button-group {
  display: flex;
  justify-content: center;
}

.preview-image {
  width: 100%;
  max-width: 320px;
  border-radius: 10px;
  margin-top: 20px;
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.loading-spinner {
  margin-top: 20px;
}

.camera-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.preview-image {
  max-width: 100%;
  margin-bottom: 20px;
}

.upload-button {
  margin-top: 10px;
}

.callback-modal-content {
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
}

.receipt-status-modal {
  padding: 24px;
  text-align: center;
}

.receipt-status-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
}

.receipt-status-count {
  font-size: 16px;
  margin-bottom: 24px;
}

.receipt-list {
  margin: 20px 0;
}

.receipt-item {
  margin: 12px 0;
}

.receipt-status {
  padding: 12px;
  border-radius: 8px;
  background-color: #f5f5f5;
}

.receipt-status-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-top: 24px;
}

.receipt-status-buttons-row {
  display: flex;
  justify-content: center;
  width: 100%;
}

.receipt-action-button {
  min-width: 160px;
  height: 40px;
  border: none;
  color: white !important;
  transition: opacity 0.3s ease;
  background-color: var(--primary-colour) !important; /* Use Meed brand color */
}

.receipt-action-button:hover {
  opacity: 0.9;
  background-color: var(
    --primary-colour
  ) !important; /* Keep Meed brand color on hover */
}

.receipt-view-button {
  color: white !important;
  border: none;
  transition: opacity 0.3s ease;
  background-color: var(--primary-colour) !important; /* Use Meed brand color */
}

.receipt-view-button:hover {
  opacity: 0.9;
  background-color: var(
    --primary-colour
  ) !important; /* Keep Meed brand color on hover */
}

.ant-collapse {
  background: #f5f5f5;
  border-radius: 8px;
}

.ant-collapse-header {
  padding: 12px !important;
}

/* Override ant-design button hover styles */
.receipt-action-button:hover,
.receipt-view-button:hover {
  color: white !important;
}
