@import url("_variables.css");
@import url("_box-sizing.css");
@import url("_visuallyhidden.css");
@import url("_html.css");
@import url("_body.css");
@import url("_fonts.css");
@import url("_container.css");
@import url("_hide-scrollbar.css");
@import url("_root.css");
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
