.discovery {
  padding: 0;
  max-width: none;
}

.Discovery-Container-Title {
  padding: 12px 32px 0;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.Discovery-Container-Title h1 {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
}

.vouchers-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 12px 20px;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.voucher-detail-modal {
  .ant-modal-content {
    border-radius: 24px;
    overflow: hidden;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  }

  .ant-modal-body {
    padding: 0;
  }
}

.voucher-detail {
  /* Remove & nesting */
}

.voucher-detail-header {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 24px 32px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #eee;
}

.voucher-detail-title {
  flex: 1;
}

.voucher-detail-title h2 {
  font-size: 24px;
  font-weight: 600;
  color: #1a1a1a;
}

.voucher-detail-title h3 {
  font-size: 16px;
  color: #666;
  margin: 0;
}

.voucher-detail-campaginName {
  font-size: 18px;
  font-weight: 700;
  color: #1a1a1a;
  margin: 32px 0 12px;
}

.voucher-detail-content {
  font-weight: 500;
}

.voucher-detail-value {
  font-size: 32px;
  font-weight: 700;
  color: #e06932;
  margin: 0 0 8px;
}

.voucher-detail-description {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  margin: 0 0 32px;
}

.voucher-detail-dates {
  background-color: #f8f9fa;
  border-radius: 12px;
  padding: 16px 24px;
  margin-bottom: 32px;
}

.voucher-detail-dates .date-item {
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 1;
}

.voucher-detail-dates .timer__icon {
  width: 16px;
  height: 16px;
  color: gray;
  display: flex;
  margin-top: -1px;
  flex-shrink: 0;
}

.voucher-detail-dates p {
  font-size: 14px;
  font-weight: 500;
  color: #1a1a1a;
  margin: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  margin-top: 1px;
}

.voucher-detail-collapse {
  background-color: #f8f9fa;
  border-radius: 12px;
  margin-bottom: 32px;
}

.voucher-detail-collapse .ant-collapse-header {
  padding: 20px 24px !important;
  font-weight: 600;
  color: #1a1a1a !important;
}

.voucher-detail-collapse .ant-collapse-content-box {
  padding: 0 24px 24px !important;
}

.voucher-detail-collapse .ant-collapse-content-box p {
  white-space: pre-wrap;
  word-break: break-word;
  margin: 0;
  text-align: left;
  font-weight: 400;
}

.voucher-detail .join-button {
  width: calc(100% - 64px);
  margin: 0 auto 32px;
  height: 48px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 12px;
  background: #e06932;
  border: none;
  box-shadow: 0 4px 12px rgba(75, 118, 219, 0.2);
}

/* Hide category tab bar */
.discovery__filters {
  display: none;
}

/* Target the modal image specifically */
.ant-modal-root
  .voucher-detail-modal
  .voucher-detail-header
  img.voucher-detail-brand-logo {
  width: 64px !important;
  height: 64px !important;
  min-width: 48px !important;
  border-radius: 8px !important;
  object-fit: cover !important;
}

/* Add this specific selector */
.ant-modal-root
  .voucher-detail-modal
  .voucher-detail__collapse
  .ant-collapse-content-box
  p {
  text-align: left !important;
  padding: 0 !important;
  margin: 0 !important;
  text-indent: 0 !important;
  font-weight: 500 !important;
}
