.profile {
  min-height: calc(100vh - 250px);
  display: flex;
  flex-direction: column;
  padding-top: 26px;
  padding-bottom: 10px;
}
.profile__head {
}
.profile__name {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: bold;
  font-size: 36px;
  line-height: 36px;
}

.profile__location {
  margin-top: 12px;
}

.profile__body {
  margin-top: 18px;
  border-top: 1px solid #b3b5bf;
  padding-top: 6px;
}
.profile__navigation {
  display: flex;
  width: fit-content;
  flex-direction: column;
}
.profile__link {
  display: block;
  padding: 12px 0;
  font-size: 18px;
  line-height: 20px;
  color: inherit;
  text-decoration: none;
}
.profile__symbol {
  margin-top: -2px;
  margin-bottom: 0;
  margin-right: 4px;
  vertical-align: top;
  fill: #f87c56;
}

.profile__footer {
  margin-top: auto;
  padding-top: 30px;
}

.profile__policy {
  padding-top: 12px;
  padding-bottom: 12px;
  display: block;
  width: fit-content;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
  color: inherit;
}

.profile__version {
  margin-top: 4px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media (400px <= width) {
  .profile__version {
    max-width: 180px;
  }
}

.profile__version-heading {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
}

.profile__version-number {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
}

.profile__footer-actions {
  margin-top: 24px;
  border-top: 1px solid #b3b5bf;
  padding-top: 10px;
}
.profile__logout {
  display: flex;
  align-items: center;
  width: fit-content;
  padding-top: 14px;
  padding-bottom: 14px;
  text-decoration: none;
  font-weight: var(--extra-bold);
  font-size: 16px;
  line-height: 20px;
  color: var(--brand);
}
.profile__logout-symbol {
  margin-top: -2px;
  margin-right: 4px;
  fill: currentColor;
}
