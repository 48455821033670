.venue {

}
    .venue__heading {
        margin-top: 0;
        margin-bottom: 0;
        font-weight: var(--black);
        font-size: 24px;
        line-height: 32px;
    }

    .venue__map {
        margin-top: 12px;
    }

    .venue__list {
        margin-top: 18px;
        display: flex;
        flex-direction: column;
        gap: 18px;
    }
        
