:root {
  --react-international-phone-height: 44px;
  --react-international-phone-border-color: var(--input-border);
  --react-international-phone-country-selector-border-color: var(
    --input-border
  );
  --react-international-phone-country-selector-background-color-hover: #f6f6f6;
  --react-international-phone-border-radius: var(--input-radius);
}

.react-international-phone-input-container {
  border-radius: var(--input-radius);
}
.react-international-phone-country-selector {
}
.react-international-phone-input-container
  .react-international-phone-country-selector-button {
  padding-left: 10px;
  padding-right: 4px;
}

.react-international-phone-input-container .react-international-phone-input {
  flex-grow: 1;
}

.react-international-phone-input-container:hover {
}
.react-international-phone-input-container:hover
  .react-international-phone-country-selector {
}
.react-international-phone-input-container:hover
  .react-international-phone-country-selector-button {
  border-color: var(--input-border-hover);
}

.react-international-phone-country-selector-dropdown__list {
}
.react-international-phone-country-selector-dropdown__list-item {
  padding: 6px 14px;
}
.react-international-phone-country-selector-dropdown__list-item-flag-emoji {
  margin-right: 11px;
}

.react-international-phone-input-container:hover
  .react-international-phone-input {
  border-color: var(--input-border-hover);
}

.react-international-phone-input-container:focus-within {
  border-color: var(--input-border-hover);
  box-shadow: 0 0 4px var(--input-border-hover);
}
