.auth {
  padding-bottom: 80px;
}
.auth__head {
  margin-bottom: 34px;
  padding: 16px;
  background-color: var(--brand);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: 0 4px 4px rgb(0 0 0 / 10%);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  color: #ffffff;
}
.auth__logo {
  margin-top: 36px;
  vertical-align: top;
  border: #ffffff 5px solid;
  border-radius: 50%;
}

.auth_business_head-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 36px;
}

.auth__back {
  display: flex;
  align-items: center;
  width: 60px;
  height: 46px;
  margin-left: calc(-1 * var(--container-padding));
  padding-left: var(--container-padding);
}
.auth__chevron {
  vertical-align: top;
  fill: #ffffff;
}

.auth__wrap {
}
.auth__form {
}
.auth__fieldset {
  padding: 0;
  margin: 0;
  border: none;
}
.auth__main-heading {
  font-weight: var(--black);
  font-size: 36px;
  line-height: 42px;
}
.phone-input {
  font-size: 16px !important;
}
.auth__main-note {
  margin-top: 17px;
  margin-bottom: 38px;
  font-size: 18px;
  line-height: 24px;
  color: #5d6164;
}

.auth__illustration {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.auth__heading {
  margin-top: 40px;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
}

.auth__note {
  margin-top: 12px;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  font-weight: lighter;
}

.auth__panel {
  margin-top: 16px;
  padding: 20px;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  border-radius: 15px;
}
.auth__field {
  margin-top: 16px;
}

.auth__field:first-child {
  margin-top: 0;
}

.auth__swap-method {
  display: block;
  width: fit-content;
  margin-left: auto;
  background-color: transparent;
  border: none;
  padding: 8px 0;
  font-family: inherit;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  color: var(--brand);
  cursor: pointer;
}

.auth__swap-method + .auth__field {
  margin-top: 8px;
}

.auth__submit {
  margin-top: 35px;
}

.auth__alternation {
  margin-top: 26px;
  font-size: 14px;
  line-height: 20px;
  color: #5d6164;
  text-align: center;
}
.auth__alt-action {
  vertical-align: baseline;
  padding: 10px 0;
  border: none;
  background-color: transparent;
  font-family: inherit;
  font-weight: var(--extra-bold);
  font-size: 14px;
  line-height: 20px;
  color: var(--brand);
  cursor: pointer;
}

.auth__code-note {
}

.auth__code-hint {
  margin-top: 12px;
  font-weight: var(--light);
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.opt_description {
  display: flex;
  margin-top: 12px;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: black;
  font-weight: lighter;
  justify-content: left;
  align-items: center;
  margin-left: 97px;
}

.opt_explain {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  font-size: 16px;
  line-height: 25px;
  text-align: left;
  color: black;
  font-weight: lighter;
  justify-content: left;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 30px;
}

.opt_explain_small {
  display: flex;
  margin-top: 12px;
  font-size: 16px;
  line-height: 25px;
  text-align: left;
  color: black;
  font-weight: lighter;
  justify-content: left;
  align-items: center;
  margin-top: 20px;
}

.orange-circle {
  width: 18px;
  height: 18px;
  background-color: #f87c56;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5%;
}

.auth__TnC {
  margin-top: 16px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.terms-button {
  color: #f87c56;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  text-decoration: none;
  cursor: pointer;
}

.terms-button:hover {
  text-decoration: underline;
}

.wellcome__voucher-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.wellcome__voucher-description {
  font-size: 16px;
  font-weight: 300;
}

.wellcome__reminder {
  margin-top: 40px;
  font-size: 14px;
  font-weight: 300;
}

.wellcome__button {
  margin-top: 10px;
}

.wellcome__powered-by {
}

.wellcome__wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: transparent;
  border-radius: 15px;
  color: black;
  /* border: 3px solid rgb(255, 255, 255);
  border-radius: 5px;
  box-shadow: inset 0px 3px 3px 1px #bababa;
   */
  padding: 20px;
}

.wellcome__panel {
  margin-top: 16px;
  margin-bottom: 24px;
  padding: 20px;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  border-radius: 15px;
}

.wellcome__logo {
  margin-top: 10%;
  vertical-align: top;
  border: #ffffff 5px solid;
  border-radius: 50%;
}

.None {
  background-color: var(--color);
  opacity: 1;
}

.Wavy {
  background-color: var(--color);
  opacity: 1;
  background-image: repeating-radial-gradient(
      circle at 0 0,
      transparent 0,
      var(--color) 19px
    ),
    repeating-linear-gradient(#f1f1f155, #f1f1f1);
}

.Circles {
  background-color: var(--color);
  opacity: 1;
  background-image: radial-gradient(
      circle at center center,
      #f1f1f1,
      var(--color)
    ),
    repeating-radial-gradient(
      circle at center center,
      #f1f1f1,
      #f1f1f1,
      19px,
      transparent 38px,
      transparent 19px
    );
  background-blend-mode: multiply;
}

.Diagonal {
  background-color: var(--color);
  opacity: 1;
  background: repeating-linear-gradient(
    45deg,
    #f1f1f1,
    #f1f1f1 9.5px,
    var(--color) 9.5px,
    var(--color) 47.5px
  );
}

.ZigZag {
  background-color: var(--color);
  opacity: 1;
  background-image: linear-gradient(135deg, #f1f1f1 25%, transparent 25%),
    linear-gradient(225deg, #f1f1f1 25%, transparent 25%),
    linear-gradient(45deg, #f1f1f1 25%, transparent 25%),
    linear-gradient(315deg, #f1f1f1 25%, var(--color) 25%);
  background-position:
    19px 0,
    19px 0,
    0 0,
    0 0;
  background-size: 38px 38px;
  background-repeat: repeat;
}

.ZigZag3D {
  background-color: var(--color);
  opacity: 1;
  background:
    linear-gradient(135deg, #f1f1f155 25%, transparent 25%) -19px 0/ 38px 38px,
    linear-gradient(225deg, #f1f1f1 25%, transparent 25%) -19px 0/ 38px 38px,
    linear-gradient(315deg, #f1f1f155 25%, transparent 25%) 0px 0/ 38px 38px,
    linear-gradient(45deg, #f1f1f1 25%, var(--color) 25%) 0px 0/ 38px 38px;
}

.Rhombus {
  background-color: var(--color);
  opacity: 1;
  background-image: linear-gradient(135deg, #f1f1f1 25%, transparent 25%),
    linear-gradient(225deg, #f1f1f1 25%, transparent 25%),
    linear-gradient(45deg, #f1f1f1 25%, transparent 25%),
    linear-gradient(315deg, #f1f1f1 25%, var(--color) 25%);
  background-position:
    19px 0,
    19px 0,
    0 0,
    0 0;
  background-size: 19px 19px;
  background-repeat: repeat;
}

.Paper {
  background-color: var(--color);
  opacity: 1;
  background-image: linear-gradient(
      #f1f1f1 3.8000000000000003px,
      transparent 3.8000000000000003px
    ),
    linear-gradient(
      90deg,
      #f1f1f1 3.8000000000000003px,
      transparent 3.8000000000000003px
    ),
    linear-gradient(
      #f1f1f1 1.9000000000000001px,
      transparent 1.9000000000000001px
    ),
    linear-gradient(
      90deg,
      #f1f1f1 1.9000000000000001px,
      var(--color) 1.9000000000000001px
    );
  background-size:
    95px 95px,
    95px 95px,
    19px 19px,
    19px 19px;
  background-position:
    -3.8000000000000003px -3.8000000000000003px,
    -3.8000000000000003px -3.8000000000000003px,
    -1.9000000000000001px -1.9000000000000001px,
    -1.9000000000000001px -1.9000000000000001px;
}

.Rectangles {
  background-color: var(--color);
  opacity: 1;
  background-image: repeating-linear-gradient(
      45deg,
      #f1f1f1 25%,
      transparent 25%,
      transparent 75%,
      #f1f1f1 75%,
      #f1f1f1
    ),
    repeating-linear-gradient(
      45deg,
      #f1f1f1 25%,
      var(--color) 25%,
      var(--color) 75%,
      #f1f1f1 75%,
      #f1f1f1
    );
  background-position:
    0 0,
    19px 19px;
  background-size: 38px 38px;
}

.Pluses {
  background-color: var(--color);
  opacity: 1;
  background:
    radial-gradient(
      circle,
      transparent 20%,
      var(--color) 20%,
      var(--color) 80%,
      transparent 80%,
      transparent
    ),
    radial-gradient(
        circle,
        transparent 20%,
        var(--color) 20%,
        var(--color) 80%,
        transparent 80%,
        transparent
      )
      47.5px 47.5px,
    linear-gradient(
        #f1f1f1 3.8000000000000003px,
        transparent 3.8000000000000003px
      )
      0 -1.9000000000000001px,
    linear-gradient(
        90deg,
        #f1f1f1 3.8000000000000003px,
        var(--color) 3.8000000000000003px
      ) -1.9000000000000001px 0;
  background-size:
    95px 95px,
    95px 95px,
    47.5px 47.5px,
    47.5px 47.5px;
}
