.home {
}

.home_scan-button {
  background-color: var(--primary-colour) !important;
  align-self: center;
  font-size: 16px;
  height: 50px;
  width: 60%;
}

.home_scan-button:hover {
  background-color: darkorange !important;
}

.home_skip-button {
  font-size: 14px;
  height: 50px;
  width: 60%;
  align-self: center;
}

.home_skip-button:hover {
  border-color: darkorange !important;
  color: darkorange !important;
}

.home__card {
}

.home__filter {
  margin-top: 6px;
}

.home__list {
}
.home__item {
  margin-top: 12px;
}

.home__item:first-child {
  margin-top: 0;
}
