.ant-modal-content {
  text-align: center;
  margin: auto;
  font-family: "Questrial", sans-serif;
}
.modal .ant-modal-title {
  /* Change this to the size you want */
  font-size: 20px;
}
.ant-modal-title {
  text-align: center;
}

p {
  text-align: center;
}

.brandName {
  padding-top: 0;
  margin-top: 0;
  font-size: 24px;
  font-weight: bold;
}

.brandImage {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  display: block;
  border-radius: 50%;
  margin-bottom: 5%;
}

.slogan {
  font-size: 18px;
  font-weight: normal;
}

.ant-modal-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-to-applepopup {
  margin: 0;
  width: 90%;
}

.skipButton {
  background: transparent;
  border: none;
  font-size: 200px;
}

.model-checkbox {
  text-align: left;
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 5px;
}

.ant-checkbox + span {
  padding-inline-start: 15px;
  font-size: 16px;
  font-weight: lighter;
  user-select: none;
}

.model-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-colour) !important;
  border-color: var(--primary-colour) !important;
}

.model-checkbox .ant-checkbox .ant-checkbox-inner {
  border-color: var(--primary-colour) !important;
}

.footer-area {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0px 20px;
}

.accept-button {
  color: white;
  font-size: 18px;
  background-color: gray;
  border: none;
  padding: 15px 30px;
  border-radius: 15px;
  cursor: pointer;
}

.activee {
  background-color: var(--primary-colour);
}
