.program {
  padding-top: 6px;
}
.program__heading {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: var(--black);
  font-size: 24px;
  line-height: 32px;
}

.program__body {
  margin-top: 12px;
}
.program__section {
  margin-top: 18px;
}

.program__section:first-child {
  margin-top: 0;
}
.about__description {
  padding: 14px 12px 22px;
  font-weight: light;
}
