.dropdown {
  width: 200px;
  background: #f8f9fa;
  border: 1px solid #dae0e5;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
  max-height: 0;
}

.dropdown.open {
  max-height: 500px; /* adjust this value to fit your content */
}
