body {
  height: 100%;
  background-color: #f0f0f0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--font);
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
  color: var(--primary-font-color);
}
