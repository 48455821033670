.back {
    display: flex;
    width: fit-content;
    align-items: center;
    gap: 10px;
    padding-top: 7px;
    padding-bottom: 7px;
    font-weight: var(--black);
    font-size: 28px;
    line-height: 36px;
    color: inherit;
    text-decoration: none;
}
    .back__icon {
        vertical-align: top;
    }
